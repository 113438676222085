import './index.css';
import {
    Headshot,
    Header,
    Footer
} from './Home';
import React from 'react';

const mailEndpoint = process.env.REACT_APP_SENDMAIL;

export const Contact = () => (
    <section>
    <div className='container'>
        <Headshot />
        <div className='content'>
        <Header />
        <ContactForm />
        <Footer />
        </div>
    </div>
    </section>
);

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            submitMessage: ' ',
            formDisabled: false
        };
    }

    render = () => (
        <div className='contact'>
            <h2>Contact:</h2>
            <form id='contact-form' onSubmit={this.handleSubmit.bind(this)} method='POST'>
                <div className='form-section'>
                    <label htmlFor='name'>Name</label>
                    <br />
                    <input type='text' className='form-input' value={this.state.name} 
                        onChange={this.onNameChange.bind(this)} disabled={this.state.formDisabled} />
                </div>
                <div className='form-section'>
                    <label htmlFor='email'>Email Address</label>
                    <br />
                    <input type='text' className='form-input' value={this.state.email} 
                        onChange={this.onEmailChange.bind(this)} disabled={this.state.formDisabled} />
                </div>
                <div className='form-section'>
                    <label htmlFor='name'>Message</label>
                    <br />
                    <textarea className='form-input' value={this.state.message} 
                        onChange={this.onMessageChange.bind(this)} disabled={this.state.formDisabled} />
                </div>
                <button className='g-recaptcha' data-sitekey={process.env.REACT_APP_CAPTCHA_KEY} data-callback="handleSubmit" 
                    type='submit' rows='5' cols='100' disabled={this.state.formDisabled} >Submit</button>
                <p className='submit-text'>{this.state.submitMessage}</p>
                <a href='/'>Return to main page</a>
            </form>
        </div>
    );
    onNameChange(event) {
        this.setState({name: event.target.value});
    }
    onEmailChange(event) {
        this.setState({email: event.target.value});
    }
    onMessageChange(event) {
        this.setState({message: event.target.value});
    }
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.name && this.state.email && this.state.message) {
            
            try {
                fetch(mailEndpoint, {
                    method: 'POST',
                    body: JSON.stringify({
                        name: this.state.name,
                        email: this.state.email,
                        message: this.state.message
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                }).then(
                    (response) => {
                        if (response.status === 200) {
                            this.setState({
                                submitMessage: 'Sent! Thanks for reaching out.',
                                formDisabled: true
                            });
                        } else {
                            this.setState({
                                submitMessage: 'Unable to send message.'
                            });
                        }
                    }
                );
            } catch(err) {
                this.setState({
                    submitMessage: 'Unable to send message.'
                });
            }


        } else if (!this.state.name) {
            this.setState({submitMessage: 'Please input name.'});
        } else if (!this.state.email) {
            this.setState({submitMessage: 'Please input email.'});
        } else if (!this.state.message) {
            this.setState({submitMessage: 'Please input message.'});
        }
    }
}