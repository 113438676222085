import './index.css';
import headshotImg from './img/headshot.jpg'

const engineerText =
  <>
    Originally starting as an Electrical Engineer in RF hardware development, I currently work as a Software Development Engineer
    at <i>IMDb</i>. If you're looking for a developer with a strong background for solving mathematical and scientific problems,
    feel free to reach out!
    <br /><br />
    I love to work on a software side project, and I'm usually cooking up some sort of simulation, rendering project, or simple game.
    My latest project which I'm very proud of is a <a href='https://blog.seanholloway.com/2022/03/13/visualizing-black-holes-with-general-relativistic-ray-tracing/'>black hole renderer</a>,
    which uses the physics of General Relativity to display the fascinating visuals of a black hole. To keep up to date with my other projects, you can check
    out my <a href="https://github.com/HollowaySean">GitHub</a>, <a href="https://hollowaysean.itch.io">itch.io</a>, or <a href="https://blog.seanholloway.com">my project blog</a>.
  </>;
const writerText =
  <>
    Outside of work, you can find me writing scripts, taking classes, and performing live comedy around Los Angeles.
    You can see me perform sketch comedy monthly at the Pack Theater with my team, <a href='https://www.packtheater.com/teams/hot-city/'>Hot City</a>.
    Other than that, I have a devastatingly low number of things to plug here yet, so until I get my act together, follow me on Twitter for an occasional joke!
  </>;
const otherText =
  <>
    On 10/4/2022, I was a contestant on <i>Jeopardy</i>! I got my ass kicked. Check it out!
  </>;

export const Headshot = () => (
    <div className='headshot'>
      <img src={headshotImg} alt='Thats-a me!'></img>
    </div>
);

export const Header = () => (
    <div className='banner'>
        <h1>Sean Holloway</h1>
        <div className='subheaders'>
            <h3>Software &<br />Electrical Engineer</h3>
            <h4>&#8226;</h4>
            <h3>Aspiring<br />Screenwriter</h3>
            <h4>&#8226;</h4>
            <h3>One-Time<br /><i>Jeopardy!</i> Loser</h3>
        </div>
    <div className='line'></div>
  </div>
);

const Intro = () => (
    <div className='intro'>
      <h2>Hello! My name is Sean, and I am:</h2>
      <h3>An Engineer</h3>
      <p>{engineerText}</p>
      <h3>A Writer and Comedian</h3>
      <p>{writerText}</p>
      <h3>A Few Other Things, Too</h3>
      <p>{otherText}</p>
    </div>
);

export const Footer = () => (
    <footer>
      <a href="https://twitter.com/coolseandotcom">Twitter</a>
      <p>|</p>
      <a href="https://www.linkedin.com/in/sean-holloway-53970664/">LinkedIn</a>
      <p>|</p>
      <a href="https://github.com/HollowaySean">GitHub</a>
      <p>|</p>
      <a href="https://hollowaysean.itch.io">itch.io</a>
      <p>|</p>
      <a href="https://blog.seanholloway.com">Blog</a>
      <p>|</p>
      <a href="/contact">Contact</a>
    </footer>
);

export const Home = () => (
<section>
    <div className='container'>
    <Headshot />
    <div className='content'>
        <Header />
        <Intro />
        <Footer />
    </div>
    </div>
</section>
);